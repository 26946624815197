import React from "react";
import { FormType } from "../../utils/constants";
import { Grid } from "semantic-ui-react";
import UserCreateEditForm from "../../forms/UserCreateEditForm";
import TecfinityAccountLinkModal from "../TecfinityAccountLinkModal";
import TecfinityAccountCreationModal from "../TecfinityAccountCreationModal";

const UserCreateEdit = ({
	user,
	userId,
	tradeAccountToLink,
	orders,
	addresses,
	countries,
	provinces,
	salesPeople,
	formType,
	onBackClick,
	showTecfinityAccountLinkModal,
	showTecfinityAccountCreationModal,
	closeModals,
	submit,
	onLinkTecfinityAccountClick,
	onCreateTecfinityAccountClick,
}) => {

	return (
		<div>
			<div className="argus-title-background mb-5">
				<div className="argus-title">
					User
				</div>
			</div>
			<Grid container>
				<Grid.Row verticalAlign="middle" textAlign="center">
					{formType === FormType.Edit && !!user &&
						<UserCreateEditForm
							formType={formType}
							user={user}
							orders={orders}
							addresses={addresses}
							provinces={provinces}
							countries={countries}
							salesPeople={salesPeople}
							goBack={onBackClick}
							submit={submit}
							onLinkTecfinityAccountClick={onLinkTecfinityAccountClick}
							onCreateTecfinityAccountClick={onCreateTecfinityAccountClick}
						/>
					}
				</Grid.Row>
				{!!showTecfinityAccountLinkModal &&
					<TecfinityAccountLinkModal
						open={showTecfinityAccountLinkModal}
						setClose={closeModals}
						userToEdit={user}
						userId={userId}
						tradeAccount={tradeAccountToLink}
					/>
				}
				{!!showTecfinityAccountCreationModal &&
					<TecfinityAccountCreationModal
						open={showTecfinityAccountCreationModal}
						setClose={closeModals}
						userToEdit={user}
						userId={userId}
					/>
				}
			</Grid>
		</div>
	);
};

export default UserCreateEdit;