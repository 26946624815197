import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiCallStatus, FormType } from "../../utils/constants";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import {
    fetchCountryData,
    fetchProvinceData,
    fetchSalesPeopleData
} from "../PageFunctions/commonFunctions";
import { fetchDetailedUserData } from "../PageFunctions/userFunctions";
import UserCreateEdit from "../../components/UserCreateEdit";
import { fetchOrdersDataByUserId, fetchUserAddressesData } from "../PageFunctions/orderFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const ModalName = Object.freeze({
	TecfinityLink: Symbol(1),
	TecfinityDeLink: Symbol(2),
	TecfinityCreate: Symbol(3),
});

const UserCreateEditPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {userId} = useParams();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();
	const navigate = useNavigate();
	const [userData, setUserData] = useState(null);
	const [orderData, setOrderData] = useState(null);
	const [addressesData, setAddressesData] = useState(null);
	const [countryData, setCountryData] = useState(null);
	const [provinceData, setProvinceData] = useState(null);
	const [salesPeopleData, setSalesPeopleData] = useState(null);
	const [tradeAccountToLink, setTradeAccountToLink] = useState(null);

	const [openModal, setOpenModal] = useState(null);

	const [fetchUserDataCallStatus, setFetchUserDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchOrderDataCallStatus, setFetchOrderDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchSalesPeopleDataCallStatus, setFetchSalesPeopleDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchAddressesDataCallStatus, setFetchAddressesDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchProvinceDataCallStatus, setFetchProvinceDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchCountryDataCallStatus, setFetchCountryDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const gotoUsersPage = () => navigate("/users");
	const reloadPage = () => navigate(0);

	const onLinkTecfinityAccountClick = (account) => {
		setTradeAccountToLink(account);
	};

	const onCreateTecfinityAccountClick = () => {
		openTecfinityAccountCreationModal();
	};

	const fetchUserDataAsync = async () => {
		if (!!userId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchDetailedUserData({
				auth0Token,
				userId,
				setUser: setUserData,
				setStatusInProgress: () => setFetchUserDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchUserDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchUserDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchUserDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const fetchSalesPeopleDataAsync = async () => {
		if (!!userId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchSalesPeopleData({
				auth0Token,
				setSalesPeople: setSalesPeopleData,
				setStatusInProgress: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchSalesPeopleDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const fetchCountryDataAsync = async () => {
		if (!!userId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchCountryData({
				auth0Token,
				setCountries: setCountryData,
				setStatusInProgress: () => setFetchCountryDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchCountryDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchCountryDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchCountryDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const fetchProvinceDataAsync = async () => {
		if (!!userId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchProvinceData({
				auth0Token,
				setProvinces: setProvinceData,
				setStatusInProgress: () => setFetchProvinceDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchProvinceDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchProvinceDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchProvinceDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const fetchAddressDataAsync = async () => {
		if (!!userId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchUserAddressesData({
				auth0Token,
				userId,
				setAddresses: setAddressesData,
				setStatusInProgress: () => setFetchAddressesDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchAddressesDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchAddressesDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchAddressesDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const fetchUserOrderDataAsync = async () => {
		if (!!userId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchOrdersDataByUserId({
				auth0Token,
				userId,
				setOrders: setOrderData,
				setStatusInProgress: () => setFetchOrderDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchOrderDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchOrderDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchOrderDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const authorizedPageLoad = async () => {
		await Promise.all([
			fetchUserDataAsync(),
			fetchUserOrderDataAsync(),
			fetchSalesPeopleDataAsync(),
			fetchAddressDataAsync(),
			fetchProvinceDataAsync(),
			fetchCountryDataAsync()
		]);
	};

	const openTecfinityAccountLinkModal = () => {
		setOpenModal(ModalName.TecfinityLink);
	};

	const openTecfinityAccountCreationModal = () => {
		setOpenModal(ModalName.TecfinityCreate);
	};

	const closeModals = () => {
		setOpenModal(null);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	useEffect(() => {
		if (!!tradeAccountToLink) {
			openTecfinityAccountLinkModal();
		}
	}, [tradeAccountToLink]);

	const allLoadingStates = [
		fetchUserDataCallStatus,
		fetchOrderDataCallStatus,
		fetchSalesPeopleDataCallStatus,
		fetchAddressesDataCallStatus,
		fetchProvinceDataCallStatus,
		fetchCountryDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;;

	return (
		<Fragment>
			<UserCreateEdit
				formType={!!userId ? FormType.Edit : FormType.Create}
				onBackClick={gotoUsersPage}
				showTecfinityAccountLinkModal={openModal === ModalName.TecfinityLink}
				showTecfinityAccountCreationModal={openModal === ModalName.TecfinityCreate}
				onLinkTecfinityAccountClick={onLinkTecfinityAccountClick}
				onCreateTecfinityAccountClick={onCreateTecfinityAccountClick}
				tradeAccountToLink={tradeAccountToLink}
				user={userData}
				orders={orderData}
				addresses={addressesData}
				provinces={provinceData}
				closeModals={closeModals}
				countries={countryData}
				userId={userId}
				salesPeople={salesPeopleData}/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default UserCreateEditPage;